'use client'

import IArticle from '@/app/models/article';
import PageBlogHome from "../blogComponent";

interface IProps {
  articleList: IArticle[] | []
}

export default function ArticleHomePage(props: IProps) {
  return (
    <div>
      {props.articleList && props.articleList.length > 0 &&
        <div className="bg-Bgbottom bg-no-repeat bg-bottom bg-cover">
          <PageBlogHome articleList={props.articleList} />
        </div>
      }
    </div>
  )
}