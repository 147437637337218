const apiPath = {
  package: '/website/home-page/package/group',
  package_tier: '/website/home-page/tier/list',
  package_expire: '/website/home-page/expire/list',
  article: '/website/home-page/article/list',
  articleList: '/website/article-page/list',
  articleById: '/website/article-page/:articleId/detail',
  faq: '/website/faq-page/list',
  pdpa: '/website/home-page/pdpa',
  aboutus: '/website/aboutus-page/content',
  partner: '/website/home-page/partner/list',
  manageTags: 'website/home-page/create/history-ads'
}

export default apiPath
