'use client'

import { useState, useEffect } from 'react'
import Image from 'next/image'
import IDataHomeHead from '@/app/models/homeHead'

const ImageLoader = () => <div className="animate-pulse bg-gray-200 w-full h-full rounded-lg"></div>

export default function MobileModeHeadPromod({ dataHome }: { dataHome: IDataHomeHead[] | [] }) {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [mainImageLoaded, setMainImageLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setImagesLoaded(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="w-full desktop:hidden laptop:block tabletHorizontal:block mobile:block">
      <div className="w-full flex justify-center relative">
        {!mainImageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
        <Image
          src="https://sli2go-image-bucket.s3.ap-southeast-1.amazonaws.com/slip2go/website/home-promo-mobile.webp"
          alt="HomePromoNonDesk"
          width={100}
          height={100}
          className={`max-w-[548px] w-full h-auto ${!mainImageLoaded ? 'opacity-0' : 'opacity-100'}`}
          priority={true}
          loading="eager"
          quality={50}
          sizes="(max-width: 768px) 100vw, 548px"
          onLoad={() => setMainImageLoaded(true)}
          onError={() => setMainImageLoaded(true)}
        />
      </div>

      <div className="pt-3 grid grid-cols-1 desktop:hidden laptop:grid tabletHorizontal:grid mobile:hidden">
        {(imagesLoaded || mainImageLoaded) && dataHome && dataHome.length > 0
          ? dataHome.map((item: IDataHomeHead, index: number) => {
              return (
                <div
                  key={index}
                  className="relative rounded-full mt-4 w-full h-[107px] flex items-center bg-white-true bg-opacity-50 px-[22px] drop-shadow-homeBoxContent"
                >
                  <div className="rounded-full w-full">
                    <div className="rounded-full bg-gradient-to-r w-[106px] h-[107px] absolute top-0 left-0 from-primary-ocean-blue-base to-secondary-violeta-base">
                      <div className="h-full flex flex-col justify-center items-center">
                        <div>
                          <Image
                            src={item.image}
                            alt={item.imageAlt}
                            width={40}
                            height={40}
                            loading="lazy"
                            sizes="40px"
                          />
                        </div>
                        <h2 className="text-white-true text-sukhumvit16 font-sukhumvit font-sukhumvitw600">
                          {item.imageString}
                        </h2>
                      </div>
                    </div>
                    <div className="flex justify-between w-full pl-[107px] items-center">
                      <div>
                        <h2 className="pb-1 text-sukhumvit16 font-sukhumvit font-sukhumvitw600 text-transparent bg-gradient-to-r from-primary-ocean-blue-700 to-secondary-violeta-700 bg-clip-text">
                          {item.title}
                        </h2>
                        <p className="text-neutral-gray-600 text-sukhumvit16 font-sukhumvit font-sukhumvitw400">
                          {item.content}
                        </p>
                      </div>
                      <Image
                        src="https://sli2go-image-bucket.s3.ap-southeast-1.amazonaws.com/slip2go/website/icon/iconArrowRight.png"
                        alt={`arrowRightColor-${index}`}
                        width={19}
                        height={19}
                        quality={85}
                        loading="lazy"
                        sizes="19px"
                      />
                    </div>
                  </div>
                </div>
              )
            })
          : Array(4)
              .fill(0)
              .map((_, index) => (
                <div key={`skeleton-${index}`} className="mt-4 w-full h-[107px]">
                  <ImageLoader />
                </div>
              ))}
      </div>

      {/* Mobile Content */}
      <div className="pt-3 mobile:grid w-full grid-cols-2 gap-1 laptop:hidden tabletHorizontal:hidden desktop:hidden">
        {(imagesLoaded || mainImageLoaded) && dataHome && dataHome.length > 0
          ? dataHome.map((itemMobile: IDataHomeHead, indexMobile: number) => {
              return (
                <div
                  key={indexMobile}
                  className="p-2 min-h-[199px] min-w-[140px] w-full bg-white rounded-rounded"
                >
                  <div className="min-w-[124px] min-h-[36px] h-[36px] w-full rounded-full bg-gradient-to-r from-primary-ocean-blue-base to-secondary-violeta-base">
                    <div className="flex items-center justify-center h-full w-full">
                      <Image
                        width={20}
                        height={20}
                        src={itemMobile.image}
                        alt={itemMobile.imageAlt}
                        priority={indexMobile < 2}
                        loading={indexMobile < 2 ? 'eager' : 'lazy'}
                        sizes="20px"
                      />
                      <h1 className="font-sukhumvit text-sukhumvit14 pl-1 text-white-true">
                        {itemMobile.imageString}
                      </h1>
                    </div>
                  </div>
                  <div className="pt-1">
                    <h2 className="w-full text-center text-transparent text-sukhumvit16 bg-clip-text bg-gradient-to-l from-primary-ocean-blue-base to-secondary-violeta-base">
                      {itemMobile.title}
                    </h2>
                    <p className="pt-1 pb-4 text-sukhumvit16 text-neutral-gray-700">
                      {itemMobile.content}
                    </p>
                  </div>
                </div>
              )
            })
          : Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={`mobile-skeleton-${index}`}
                  className="p-2 min-h-[199px] min-w-[140px] w-full"
                >
                  <ImageLoader />
                </div>
              ))}
      </div>
    </div>
  )
}
