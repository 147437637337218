'use client'

import { useState, useEffect, Suspense } from 'react'
import IDataHomeHead from '@/app/models/homeHead'
import { useSearchParams } from 'next/navigation'
import apiPath from '@/app/constants/api'
import dynamic from 'next/dynamic'

const DesktopModeHeadPromod = dynamic(() => import('./desktopMode'), {
  loading: () => <div className="h-80 w-full bg-gray-100 animate-pulse rounded-lg"></div>,
  ssr: false
})

import MobileModeHeadPromod from './mobileMode'

export default function SectionHeadPromod({ data }: { data: IDataHomeHead[] }) {
  const searchParams = useSearchParams()
  const search = searchParams.get('tags')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false)
    }, 500)

    if (search) {
      fetch(`${process.env.NEXT_PUBLIC_SLIP_API}/${apiPath.manageTags}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tag: search })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          console.log('API response:', data)
        })
        .catch((error) => {
          console.error('Error calling API:', error)
        })
    }

    return () => clearTimeout(loadingTimer)
  }, [search])

  if (isLoading) {
    return (
      <div className="w-full h-full animate-pulse">
        <div className="mx-auto w-full h-full max-w-[1200px] pt-[32px]">
          <div className="w-full h-full pb-8">
            <div className="flex items-center justify-center pb-4 w-full">
              <div className="h-16 w-3/4 bg-gray-300 rounded-lg"></div>
            </div>
            <div className="px-5 w-full">
              <div className="h-80 w-full bg-gray-200 rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full h-full">
      <div className="mx-auto w-full h-full max-w-[1200px] bg-bgMain bg-no-repeat tabletHorizontal:!bg-[center_top_6rem] desktop:!bg-[center_top_6rem] laptop:!bg-[center_top_6rem] mobile:!bg-[center_top_13rem] bg-contain desktop:pt-[32px] laptop:pt-[32px] tabletHorizontal:pt-[32px] mobile:pt-[16px]">
        <div className="w-full h-full pb-8">
          <h1 className="flex items-center justify-center desktop:pb-4 laptop:pb-3 tabletHorizontal:pb-3 mobile:pb-3 w-full font-sukhumvit">
            <p className="desktop:font-sukhumvitw700 desktop:text-sukhumvit60 laptop:text-sukhumvit40 tabletHorizontal:text-sukhumvit40 mobile:text-sukhumvit20">
              เช็คสลิป
            </p>
            <p className="text-[#00C544] desktop:font-sukhumvitw700 desktop:text-sukhumvit60 laptop:text-sukhumvit40 tabletHorizontal:text-sukhumvit40 mobile:text-sukhumvit20">
              แท้
            </p>
            <p className="desktop:font-sukhumvitw700 desktop:text-sukhumvit60 laptop:text-sukhumvit40 tabletHorizontal:text-sukhumvit40 mobile:text-sukhumvit20 pl-3.5">
              แก้ปัญหาสลิป
            </p>
            <p className="text-[#ED2B00] desktop:font-sukhumvitw700 desktop:text-sukhumvit60 laptop:text-sukhumvit40 tabletHorizontal:text-sukhumvit40 mobile:text-sukhumvit20">
              ปลอม
            </p>
          </h1>

          {/* Desktop mode */}
          <div className="px-5 desktop:block tabletHorizontal:hidden laptop:hidden mobile:hidden">
            <Suspense
              fallback={<div className="h-80 w-full bg-gray-100 animate-pulse rounded-lg"></div>}
            >
              <DesktopModeHeadPromod dataHome={data} />
            </Suspense>
          </div>

          {/* Mobile mode */}
          <div className="px-[18px]">
            <Suspense
              fallback={<div className="h-80 w-full bg-gray-100 animate-pulse rounded-lg"></div>}
            >
              <MobileModeHeadPromod dataHome={data} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}
